import styled from 'styled-components'
import { color, display, justifyContent, minWidth, space, minHeight, maxHeight } from 'styled-system'

export const BlogPostPreviewContentContainer = styled.div`
  margin-top: 15%;
  margin-left: -35%;
  background-color: white;
  ${maxHeight}
  ${minHeight}

  @media (max-width: 991px) {
    margin-top: 0;
    margin-left: 0;
  }
`

export const BlogPostPreviewImageContainer = styled.div`
  border-radius: 0.19571429rem;
  box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;
  ${minWidth}
`

export const BlogPostPreviewCardContent = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  ${space}
  ${minHeight}
`

export const BlogPostPreviewCardFooter = styled.div`
  .ant-button-hover-override {
    margin-bottom: 8px;
    background: #edcd36;
    border-color: #edcd36;

    &:hover {
      border-color: #edcd36;
      color: #edcd36;
    }
  }
  ${space}
  ${display}
  ${justifyContent}
`

export const BlogPostPreviewCardButton = styled.button`
  ${color}
  ${space}
`
