import React from 'react'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import { Button, Card, Col, Row } from 'antd'
import {
  BlogPostPreviewContentContainer,
  BlogPostPreviewImageContainer,
  BlogPostPreviewCardContent,
  BlogPostPreviewCardFooter,
} from './blogPostPreviewCard.style'
import BannerBG from '../../../../assets/image/banner-texture.png'

const DEFAULT_BLOG_IMAGE_PATH = '../../../../images/ravenii-logo-large.png'

// @TODO: this is duplicated, move it to a common spot
const blogPostPreviewCardTitleStyle = {
  color: '#ffffff',
  textAlign: 'center',
  fontSize: '1.50rem',
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: '-0.025em',
  background: `linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%), url(${BannerBG})`,
  maxWidth: '100vw',
}

const blogImage = header => {
  if (!header) {
    return (
      <BlogPostPreviewImageContainer>
        <StaticImage src={DEFAULT_BLOG_IMAGE_PATH} height={350} />
      </BlogPostPreviewImageContainer>
    )
  }

  const image = getImage(header)
  image.layout = 'fullWidth'

  return (
    <BlogPostPreviewImageContainer maxHeight={450}>
      <GatsbyImage alt={`blog preview image`} image={image} />
    </BlogPostPreviewImageContainer>
  )
}

const BlogPostPreviewCard = ({ markdownNode, paddingBottom }) => {
  return (
    <Row style={{ paddingBottom: paddingBottom ? paddingBottom : 0 }}>
      <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 10, offset: 3 }}>
        {blogImage(markdownNode.frontmatter.header)}
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 8, offset: 0 }}>
        <BlogPostPreviewContentContainer>
          <Card title={markdownNode.frontmatter.title} headStyle={blogPostPreviewCardTitleStyle}>
            <BlogPostPreviewCardContent mb={16} padding={1} minHeight={300}>
              {markdownNode.excerpt}
            </BlogPostPreviewCardContent>
            <BlogPostPreviewCardFooter display={'flex'} justifyContent={'center'}>
              <Button size={'large'} className={'ant-button-hover-override'}>
                <a href={`/blog${markdownNode.fields.slug}`}>View Blog Post</a>
              </Button>
            </BlogPostPreviewCardFooter>
          </Card>
        </BlogPostPreviewContentContainer>
      </Col>
    </Row>
  )
}

export default BlogPostPreviewCard
