import styled from 'styled-components'
import BannerBG from '../../../assets/image/banner-texture.png'

export const TopFoldContentWapper = styled.section`
  padding: 85px 0 0 0;
`

export const ImageSection = styled.section`
  background-image: url(${BannerBG}), linear-gradient(35deg, rgb(99, 23, 25, 39) 0%, rgb(176, 58, 62, 69) 100%);
  background-size: cover;
  background-position: top center;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`

export const ImageTextOverlayWrapper = styled.section`
  z-index: 999;
  position: absolute;
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 15px 40px 15px 40px;
  background-image: url(${BannerBG}), linear-gradient(35deg, rgba(99, 23, 25, 0.6) 0%, rgba(176, 58, 62, 0.6) 100%);

  @media (min-width: 1300px) {
    top: 35%;
    font-size: 28px;
  }

  @media (min-width: 1200px) and (max-width: 1299px) {
    top: 35%;
    font-size: 24px;
  }

  @media (min-width: 800px) and (max-width: 1199px) {
    top: 50%;
    font-size: 24px;
  }

  @media (min-width: 600px) and (max-width: 799px) {
    top: 50%;
    font-size: 20px;
  }

  @media (min-width: 500px) and (max-width: 599px) {
    top: 50%;
    font-size: 16px;
  }

  @media (max-width: 499px) {
    top: 35%;
    font-size: 10px;
  }

  .force-italic-font {
    font-style: italic;
  }
`

export const ContactSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const ContentSection = styled.section`
  margin-top: 25px;
`

export const ServicesSection = styled.section`
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #e8e8e8;
`
