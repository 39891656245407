import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Button, Card, Col, Row } from 'antd'
import LightSpeed from 'react-reveal/LightSpeed'
import Text from '../../atoms/Text'
import ContactUsSection from '..//ContactUs'
import { TopFoldContentWapper, ImageSection, ImageTextOverlayWrapper, ContactSection, ContentSection, ServicesSection } from './homePageLanding.style'
import Box from '../../atoms/Box'
import BlogPostPreviewCard from '../../common/blog/BlogPostPreviewCard'

import BannerBG from '../../../assets/image/banner-texture.png'
import IncidentResponseModal from '../../common/modals/IncidentResponse'

const cardTitleStyle = {
  color: '#ffffff',
  fontSize: '1.50rem',
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: '-0.025em',
  background: `linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%), url(${BannerBG})`,
  maxWidth: '100vw',
}

const cardStyle = {
  marginTop: '1rem',
  marginRight: '2rem',
  marginBottom: '1rem',
  marginLeft: '1rem',
  fontSize: '1.5rem',
}

const serviceCardTitleStyle = {
  color: '#ffffff',
  fontSize: '1.25rem',
  background: `linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%), url(${BannerBG})`,
}

const serviceCardStyle = {
  marginTop: '1rem',
  marginRight: '2rem',
  marginBottom: '1rem',
  marginLeft: '1rem',
  fontSize: '1.1rem',
}

const calloutCardTitleStyle = {
  fontSize: '1.25rem',
  backgroundColor: '#edcd36',
}

const calloutCardStyle = {
  marginTop: '1rem',
  marginRight: '2rem',
  marginBottom: '1rem',
  marginLeft: '1rem',
  fontSize: '1.1rem',
}

const HomePageLandingSection = ({ data, contentWrapper, textOverlay, quoteSignature }) => {
  const { services, blogs } = data
  const StaticData = useStaticQuery(graphql`
    query {
      dataJson {
        HOME_PAGE {
          imageOverlayQuote
          imageOverlayQuoteAttribution
        }
      }
    }
  `)
  const homePageData = StaticData.dataJson.HOME_PAGE[0]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <TopFoldContentWapper>
        <Row>
          <Col xs={{ span: 24 }} xxl={{ span: 15, offset: 1 }}>
            <ImageSection {...contentWrapper}>
              <StaticImage src='../../../images/home-background.jpg' layout='fullWidth' alt='home page landing image'></StaticImage>
              <ImageTextOverlayWrapper>
                <LightSpeed>
                  <Text content={homePageData.imageOverlayQuote} {...textOverlay} />
                  <Text className='force-italic-font' content={homePageData.imageOverlayQuoteAttribution} {...textOverlay} {...quoteSignature} />
                </LightSpeed>
              </ImageTextOverlayWrapper>
            </ImageSection>
          </Col>
          <Col xs={{ span: 0 }} xxl={{ span: 7 }}>
            <ContactSection>
              <ContactUsSection alignItems={'center'} />
            </ContactSection>
          </Col>
        </Row>
      </TopFoldContentWapper>

      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xxl={{ span: 0 }}>
          <ContactSection>
            <ContactUsSection alignItems={'center'} />
          </ContactSection>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xxl={{ span: 24 }}>
          <ContentSection>
            <Card
              title={'RAVENii - Leading Managed Security Service Provider'}
              className='ant-card-header-override'
              headStyle={cardTitleStyle}
              style={cardStyle}
            >
              <Box>
                RAVENii solves today's most complex cyber security challenges for our clients by providing comprehensive, scalable network security for any size
                company's network. Our solutions and our team adapt to your data and infrastructure needs. RAVENii takes a proactive risk-based approach to
                pinpoint vulnerabilities. We target threats to your network security, the where, when, and how, and then apply risk-based remediation efforts.
                Threats are stopped before they reach your network.
              </Box>
            </Card>
          </ContentSection>
        </Col>
      </Row>

      <ServicesSection>
        <Row align='center' style={{ marginBottom: 24 }}>
          <Col xs={{ span: 23 }}>
            <Card title={'Services'} headStyle={{ ...cardTitleStyle, textAlign: 'center', background: '#fff', color: '#595959' }} style={cardStyle}>
              <Row align='center'>
                {services.nodes.map(service => {
                  return (
                    <Col key={`${service.frontmatter.title}-Card`} md={{ span: 23 }} xxl={{ span: 12 }}>
                      <Link id={`${service.frontmatter.title}`} to={`/services${service.fields.slug}`}>
                        <Card title={service.frontmatter.title} headStyle={serviceCardTitleStyle} style={serviceCardStyle} hoverable>
                          {service.frontmatter.previewText}
                        </Card>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            </Card>
          </Col>
        </Row>
      </ServicesSection>

      <Row align='center' style={{ marginBottom: 24 }}>
        <Col xs={{ span: 23 }}>
          <ContentSection>
            <Card title={'Emergency IT Support'} headStyle={calloutCardTitleStyle} style={calloutCardStyle}>
              <Box>
                RAVENii is here when you need immediate Network Security help! Our team responds quickly in the event of Cyber Attack, Zero-Day Attacks, or any
                other threat to your network environment.
              </Box>
              <Row justify='center'>
                <Col xs={8}>
                  <Box style={{ marginTop: 25 }}>
                    <Button size='large' block type='primary' onClick={() => setIsModalOpen(true)}>
                      Contact Support
                    </Button>
                  </Box>
                </Col>
              </Row>
            </Card>
          </ContentSection>
        </Col>
      </Row>

      <Row style={{ marginBottom: 25 }}>
        <Col xs={{ span: 23 }}>
          {blogs
            ? blogs.nodes.map(node => {
                return <BlogPostPreviewCard key={`${node.frontmatter.title}-Blog`} markdownNode={node} paddingBottom={32} />
              })
            : null}
        </Col>
      </Row>

      <IncidentResponseModal isOpen={isModalOpen} onClose={closeModal}></IncidentResponseModal>

      {/* @NOTE: this is a bit of a hack but to enable using netlify forms the way we wanted for this form we had to give the netlify bots something they could parse */}
      <form name='pentestvulnassessment' method='POST' data-netlify='true' hidden>
        <input type='text' name='form-name' />
        <input type='textarea' name='answers' />
      </form>
    </>
  )
}

HomePageLandingSection.propTypes = {
  contentWrapper: PropTypes.object,
  textOverlay: PropTypes.object,
  quoteSignature: PropTypes.object,
}

HomePageLandingSection.defaultProps = {
  contentWrapper: {
    width: '100%',
  },
  textOverlay: {
    opacity: 3,
    fontWeight: 400,
    color: '#fff',
    lineHeight: 1.2,
    letterSpacing: '-0.025em',
  },
  quoteSignature: {
    textAlign: 'right',
  },
}

export default HomePageLandingSection
