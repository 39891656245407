import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import HomePageLandingSection from '../components/containers/HomePageLanding'
import Footer from '../components/layout/Footer'

const Index = ({ data, location }) => {
  return (
    <ThemeProvider theme={raveniiTheme}>
      <Fragment>
        <Seo title='RAVENii' />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={-1} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <HomePageLandingSection data={data} />

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    services: allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }, filter: { fields: { collection: { eq: "services" } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          previewText
        }
      }
    }

    blogs: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { fields: { collection: { eq: "blog" } } }, limit: 3) {
      nodes {
        excerpt(pruneLength: 750)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          featured
          tags
          categories
          header {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
